import { io } from 'socket.io-client'
import React, { useEffect } from 'react'
import NotificationsManager from '../../services/NotificationsManager'
import { useCollectionStore } from '../../state/collectionStore'
import { useIrriStatusStore } from '../../state/irriStatusStore'
import { useCommonStore } from '../../state/commonStore'
import { AGRICULTURAL_PERIOD_DEPENDANT_COLLECTIONS } from '../../utils/constants'

interface Props {
  loadFeatures: (featureType: string, doReload?: boolean) => any
}

/** Upon initial Load it will fire a useEffect that will initialize all web socket events listeners
 * No html is returned from <WebSocketsContainer> it's sole purpose is initialize the web sockets
 * !!!WARNING: any references to the state inside the event listeners will be the stale!
 * aka: the state will be an exact copy of the state when the useEffect was fired
 * !!!if any state functionality is to be added in the useEffect it MUST be added to the DEPENDANCY ARRAY of the useEffect
 * this ofcourse will trigger a re-render when the state/collection is updated
 * during re-render the sockets and their listeners will be destroyed by the cleanup and new ones will be re-created upon re-render
 */
const WebSocketsContainer: React.FC<Props> = ({
  loadFeatures,
}) => {
  const notificationsManager = NotificationsManager()
  const personnels = useCollectionStore(state => state.personnels)
  const profile = useCommonStore(state => state.profile)
  const addCollectionItems = useCollectionStore(state => state.addItems)
  const updateCollectionItem = useCollectionStore(state => state.updateItem)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)

  useEffect(() => {
    console.log(`<WebSocketsContainer> renders, initializing socket listeners...`)
    let socket = io(
      // 'http://localhost:3003',
      `${process.env.REACT_APP_WRM_API_URL}`,
      {
        transports: ['websocket'], //important (or else defaults to http-polling, which is blocked by cors)
        query: { user: `${profile?.auth0User.user_id}` }, //custom query (payload) that will be sent to the server upon connection
      }
    )

    socket.on('connect', () => {
      console.log(`socket connected: ${socket.id}`)
      // console.log(socket)

      //Join the room (tenant) - maybe this will be handy for later
      // const room = profile.selectedTenant
      // socket.emit('join-room', room)
    })

    socket.on('disconnect', () => {
      console.log(`socket disconnected: ${socket.id}`)
    })

    socket.on('connect_error', err => {
      console.log(`socket connect_error due to ${err.message}`)
    })

    socket.on('reloadNotifications', (data: any) => {
      if (!!profile?.consumer) {
        try {
          notificationsManager.loadConsumerNotifications(profile.consumer._id, data)
        } catch (err) {
          console.log(`socket event "reloadNotifications" error: ${err}`)
        }
      } else {
        const currentUsersPersonnel = personnels?.find(p => p.users_user_id === profile?.user_id)
        try {
          notificationsManager.loadPersonnelNotifications(currentUsersPersonnel!._id, data)
        } catch (err) {
          console.log(`socket event "reloadNotifications" error: ${err}`)
        }
      }
    })

    socket.on('reloadFeatures', (data: any) => {
      console.log(`reloadFeatures data:`)
      console.log(data)
      try {
        loadFeatures(data.featureType, true)
      } catch (error) {
        console.log(`socket event "reloadFeatures" error: ${error}`)
      }
    })
    socket.on('reloadIrriState', (data: any) => {
      console.log(`reloadIrriState data:`)
      console.log(data)
      try {
        useIrriStatusStore.getState().setStationState(data)
      } catch (error) {
        console.log(`socket event "reloadIrriState" error: ${error}`)
      }
    })
    socket.on('reloadInverterState', (data: any) => {
      console.log(`reloadInverterState data:`)
      console.log(data)
      try {
        useIrriStatusStore.getState().setStationInverterState(data)
      } catch (error) {
        console.log(`socket event "reloadInverterState" error: ${error}`)
      }
    })
    socket.on('reloadIrriEvents', (data: any) => {
      console.log(`reloadIrriEvents data:`)
      console.log(data)
      /**Filter out new events if the user has switched to a `completed` agricultural period */
      const validEvents = data.items.filter(event => {
        const eventDate = event?.createdAt
          ? new Date(event.createdAt).getFullYear()
          : new Date(event.refDatetime).getFullYear()
        return eventDate == currentAgriculturalPeriod?.cultivationPeriod
      })
      try {
        if (validEvents.length > 0) {
          const events = useCollectionStore.getState().irriEvents
          if (events && events.length > 0) {
            addCollectionItems('irriEvents', validEvents)
          }
          // refreh station state
          if (!data.doNotUpdateState) {
            useIrriStatusStore.getState().updateStationStateOnEvents(validEvents)
          }

          //refresh inverter state as well if needed
          const statebuildingEvents: string[] = ['inverterReport', 'inverterEvent']
          let inverterEvents = validEvents.filter(e => statebuildingEvents.includes(e.event))
          if (inverterEvents.length > 0) {
            let latestEvent = inverterEvents[inverterEvents.length - 1]
            let partialState = {...latestEvent.other}
            partialState.stationCode = latestEvent.stationCode //do we need this?
            partialState.lastUpdatedAt = latestEvent.refDatetime
            partialState.macAddress = latestEvent.macAddress
            useIrriStatusStore.getState().setStationInverterState(partialState)
          }
        }
      } catch (error) {
        console.log(`socket event "reloadIrriEvents" error: ${error}`)
      }
    })
    socket.on('reloadIrriCommands', (data: any) => {
      console.log(`reloadIrriCommands data:`)
      console.log(data)
      try {
        if (!!data) {
          /* If this is the same socket that issued the command
           * then we do not want the command to be added to the collection.
           * Therefore we wait one second for the collection to be updated by the service. */
          setTimeout(() => {
            const commands = useCollectionStore.getState().irriCommands
            //Also check that the incoming IrriCommand belongs to the currentAgriculturalPeriod
            if (
              !!commands &&
              new Date(data.createdAt).getFullYear() === currentAgriculturalPeriod?.cultivationPeriod
            ) {
              let command = commands.find(c => c.code === data.code)
              if (!command) {
                addCollectionItems('irriCommands', [data])
              } else {
                updateCollectionItem('irriCommands', data)
              }
            }
          }, 1000)
        }
      } catch (error) {
        console.log(`socket event "reloadIrriCommands" error: ${error}`)
      }
    })
    socket.on('refreshCollection', (data: any) => {
      console.log(`refreshCollection data:`)
      console.log(data)
      /**Item is Valid if it's cultivationPeriod is the same as the currentAgriculturalPeriod.cultivationPeriod (eg 2023) */
      let itemIsValid = true
      const collection = data?.collection
      if (AGRICULTURAL_PERIOD_DEPENDANT_COLLECTIONS.includes(collection)) {
        const itemAgrPeriod =
          collection === 'irriCommands' || collection === 'irriEvents'
            ? new Date(data?.item?.createdAt).getFullYear()
            : data?.item?.cultivationPeriod
        itemIsValid = itemAgrPeriod == currentAgriculturalPeriod?.cultivationPeriod
      }
      try {
        if (data.collection && data.action && data.item && itemIsValid) {
          /* If this is the same socket that called the service method which initiated the collection modification,
           * then we do not want the collection to be refreshed.
           * Therefore we wait one second for the collection to be updated by the service. */
          setTimeout(() => {
            const collectionItems = useCollectionStore.getState()[data.collection]
            if (!!collectionItems) {
              switch (data.action) {
                case 'add':
                  if (collectionItems.findIndex(c => c._id === data.item._id) === -1) {
                    useCollectionStore.getState().addItem(data.collection, data.item)
                  }
                  break
                case 'update':
                  useCollectionStore.getState().updateItem(data.collection, data.item)
                  break
                case 'delete':
                  useCollectionStore.getState().deleteItem(data.collection, data.item)
                  break

                default:
                  break
              }
            }
          }, 1000)
        }
      } catch (error) {
        console.log(`socket event "refreshCollection" error: ${error}`)
      }
    })

    /** CleanUp function will run when the the dependency array changes
     * Currently if the logged user is Personnel it will re-render once (when the personnel collection is fetched)
     * If the logged user is a Consumer then it will re-render (when profile.consumer is updated)
     * io.socket.off() does not stop the client-side socket from receiving any server-sent messages,
     * it just prevents the specified event handler from firing.
     * the socket.disconnect disconnects the socket (unlike socket.off it actually triggers the disconnect event)
     * Without socket.disconnect() the disconnect listener never runs on server side, thus the backend
     * keeps accumulating dead sockets.
     */
    return () => {
      console.log(`Sockets cleanup...`)
      socket.off('connect')
      socket.off('disconnect')
      socket.off('connect_error')
      socket.off('reloadNotifications')
      socket.off('reloadFeatures')
      socket.off('reloadIrriState')
      socket.off('reloadIrriEvents')
      socket.off('reloadIrriCommands')
      socket.off('refreshCollection')
      socket.disconnect()
    }
    // }, [notifications, setNotifications, personnels, profile?.consumer])
  }, [personnels, profile?.consumer, currentAgriculturalPeriod])

  return null
}

export default WebSocketsContainer
