import { create } from 'zustand'
// import { devtools } from 'zustand/middleware'

import { PointCoordinates, CircleMarkerParams } from '../utils/types/mapStoreTypes'
import { GenericFeature, Pump } from '../utils/types/networkElementTypes'
import { DEFAULT_NETWORK_ELEMENTS_FILTER } from '../utils/constants'
import { NetworkElementsFilter } from '../utils/types/mapStoreTypes'
import moment from 'moment'

interface UiStore {
  setPartialState: (partialState: any) => void
  /**The Status of the modem for each controller, stores the state in an object where the key is the macAdress of the controllers
   * and the value is a timestamp of the last time the modem status was switched on for that particular contoller  */
  modemStatus: {
    [key: string]: Date | moment.Moment;
  },

  setModemStatus: (macAddress: string, timeStamp:Date | moment.Moment) => void

  loadingMultipleSeries: boolean
  setLoadingMultipleSeries: (val: boolean) => void

  selectedEntity: GenericFeature | null
  setSelectedEntity: (feature: GenericFeature | null) => void

  // actSelectFeature: (feature: GenericFeature | null | undefined, optionalFeatureDetailsTab?: string) => void
  actSelectFeature: (feature: any, optionalFeatureDetailsTab?: string) => void
  setActSelectFeature: (actSelectFeatureImplementation: any) => void
  /**Filter used by search by coordinates/search by attributes */
  networkElementsFilter: NetworkElementsFilter
  /**!Warning the networkElementsFilter state is deeply nested when updating the networkElementsFilter, we replace the old state with the new
   * when setting the networkElementsFilter use destructuring or something similar in order not to accidentally remove parts of the state   */
  setNetworkElementsFilter: (newFeatureFilter: NetworkElementsFilter) => void
  featureDetailsTab: any
  setFeatureDetailsTab: (val: any) => void
  isFeatureDetailsDocked: boolean
  setIsFeatureDetailsDocked: (val: boolean) => void

  entitiesOpen: boolean
  setEntitiesOpen: (val: boolean) => void

  /** Dialogs that overlay with the map (contentKey==='entities') (userSettings,Notifications,ImportDataDialog) */
  entitiesOverlayOpen: boolean
  setEntitiesOverlayOpen: (val: boolean) => void
  entitiesOverlayKey: string
  setEntitiesOverlayKey: (key: 'NOTIFICATIONS' | 'USER_SETTINGS_DIALOG' | '') => void

  /**For mobile users the contentKey will be locked to `inverterMobile` and all the navigation will be handled by `contentKeyLev2`*/
  contentKey: string
  setContentKey: (key: string) => void
  /**In case the user is logged in from a small mobile device 
   * all the mobile navigation will be handled by the `contentKeyLev2` */
  contentKeyLev2: string
  /**Sets the contentKeyLevel2 of the navigation (eg tabs, or in irrigation mobile app `currentScreen`)  */
  setContentKeyLev2: (key: string) => void
  /**This field will be populated upon certain UI interaction (navigation), 
   * Pressing the `Back Button Icon` or the Android onscreen `Back Button` will navigate the user to the previousScreen */
  previousScreens: string[]
  setPreviousScreens: (screens:string[]) => void
  filterNotificationsBySelectedElement: boolean
  setFilterNotificationsBySelectedElement: (value:boolean)=>void
  selectedIssueItem: any | null
  setSelectedIssueItem: (issue: any | null) => void
  isAddNetworkFromCons: boolean
  setIsAddNetworkFromCons: (val: boolean) => void
  
  isEditingGeometry: boolean
  editingFeature: any
  setEditingFeature: (val: any) => void

  isDrawingGeometry: boolean
  drawingFeature: any
  setDrawingFeature: (val: any) => void
  drawing_id: number
  setDrawing_id: (val: number) => void

  doSaveGeometry: boolean
  setDoSaveGeometry: (val: boolean) => void
  doCancelGeometry: boolean
  setDoCancelGeometry: (val: boolean) => void
  doCancelDrawing: boolean
  setDoCancelDrawing: (val: boolean) => void

  actEditingGeometry: (start: boolean) => void
  actDrawingGeometry: (start: boolean) => void

  //Methods from the deprecated useMapStore
  /**Ref pointing to the leafletElement (Map Element instance) of the <LMap>, ⚠️ always check if it is truthy before using it! */
  mainMap: any | undefined
  setMainMap: (any) => void
  /** Expects the coordinates to be an array of [longitude(γεωγραφικό μήκος), latitude(γεωγραφικό πλάτος),] - (geoJSON format)
   *  passing a set of geoJSON (2d or 3d point) coordinates is valid even thought Typescipt will complain about type missmatch*/
  flyTo: (coords: PointCoordinates, zoom?: number, fitBoundsOptions?: any) => void
  /** Expects the coordinates to be an array of [longitude(γεωγραφικό μήκος), latitude(γεωγραφικό πλάτος),] - (geoJSON format)
   *  passing a set of geoJSON (2d or 3d point) coordinates is valid even thought Typescipt will complain about type missmatch */
  panTo: (coords: PointCoordinates, zoomPanOptions?: any) => void
  /**Temporary Circle Marker on the map (<Lmap> will render a <Circle> for each element in the array)
   * ⚠️Actually will render two circles: the first one is small and non-customizable and serves as a dot that represents the center of the circle-marker
   * and the second one is the external cirle and is customisable via the CircleMarkerParams (both inner and outer circles share the same center)   */
  circleMarkers: CircleMarkerParams[]
  /**updates the circles property, expects an array of CircleMarkerParams (only center is mandatory )
   * the center of the circle should be of in geoJSON format: [longitude(γεωγραφικό μήκος), latitude(γεωγραφικό πλάτος)]   */
  setCircleMarkers: (params: CircleMarkerParams[]) => void
  /**Mobile Navigation screen name */

  mdCompos: any
  setMdCompoState: (compoState: any) => void

  composState: any
  setCompoState: (compoState: any) => void
}

/**Devtools were disabled cause they cause significant lag during development(probably a bug of redux-devtools chrome plugin),
 * uncomment them in case you need to some extended debugging  */
const useUiStore = create<UiStore>()(
  // devtools(
    set => ({
      setPartialState: partialState => set(state => (partialState)),

      modemStatus: {},
      setModemStatus: (macAddress:string, timeStamp:Date | moment.Moment) => set(state => ({
        modemStatus: {
          ...state.modemStatus,
          [macAddress]: timeStamp
        }
       })),

      loadingMultipleSeries: false,
      setLoadingMultipleSeries: val => set(state => ({ loadingMultipleSeries: val })),

      selectedEntity: null,
      setSelectedEntity: (feature: GenericFeature | null) => {
        set({ selectedEntity: feature })
      },

      // actSelectFeature: (f: GenericFeature, optionalFeatureDetailsTab?: string) => {
      actSelectFeature: (f: any, optionalFeatureDetailsTab?: string) => {
        console.log(`placeholder implematation of actSelectFeature. feature: ${f?.properties.label}`)
      }, //just a placeholder function so that typescript doesn't complain about invoking a possibly undefined function
      setActSelectFeature: actSelectFeatureImplementation => set(state => ({ actSelectFeature: actSelectFeatureImplementation })),
      networkElementsFilter: DEFAULT_NETWORK_ELEMENTS_FILTER,
      /**!Warning the networkElementsFilter state is deeply nested we just replace all the state with each update! */
      setNetworkElementsFilter: (newFeatureFilter) => {
        set({ networkElementsFilter: newFeatureFilter });
      },

      entitiesOpen: false,
      setEntitiesOpen: val => set(state => ({ entitiesOpen: val })),
      entitiesOverlayOpen: false,
      setEntitiesOverlayOpen: val => set(state => ({ entitiesOverlayOpen: val })),
      entitiesOverlayKey: '',
      setEntitiesOverlayKey: (key) => set(state => ({ entitiesOverlayKey: key })),
      // setEntitiesOverlayKey: (key) => set(state => ({ entitiesOverlayKey: (state.entitiesOverlayKey === key ? '' : key) })),
      contentKey: '',
      setContentKey: (key) => {
        set({ contentKey: key })
      },
      contentKeyLev2: '',
      setContentKeyLev2: (key) => {
        set({ contentKeyLev2: key })
      },
      selectedIssueItem: null,
      setSelectedIssueItem: (issue: any | null) => {
        set({ selectedIssueItem: issue, isAddNetworkFromCons: true })
      },
      isAddNetworkFromCons: false,
      setIsAddNetworkFromCons: val => set(state => ({ isAddNetworkFromCons: val })),

      featureDetailsTab: 'properties',
      setFeatureDetailsTab: val => set(state => ({ featureDetailsTab: val })),
      isFeatureDetailsDocked: false,
      setIsFeatureDetailsDocked: val => set(state => ({ isFeatureDetailsDocked: val })),

      isEditingGeometry: false,
      editingFeature: null,
      setEditingFeature: val => set(state => ({ editingFeature: val })),
    
      isDrawingGeometry: false,
      drawingFeature: null,
      setDrawingFeature: val => set(state => ({ drawingFeature: val })),
      drawing_id: -1,
      setDrawing_id: val => set(state => ({ drawing_id: val })),

      doSaveGeometry: false,
      setDoSaveGeometry: val => set(state => ({ doSaveGeometry: val })),
      doCancelGeometry: false,
      setDoCancelGeometry: val => set(state => ({ doCancelGeometry: val })),
      doCancelDrawing: false,
      setDoCancelDrawing: val => set(state => ({ doCancelDrawing: val })),

      actEditingGeometry: start => set(state => {
        console.log(`actEditingGeometry start ${start}`)
        const newPartialState: any = {}
        if (start) {
          if (state.isDrawingGeometry) {
            console.log(`Cannot do that`)
            return state
          }
          if (state.featureDetailsTab !== 'geometry') {
            newPartialState.isFeatureDetailsDocked = true
            newPartialState.featureDetailsTab = 'geometry'
          }
        }
        newPartialState.isEditingGeometry = start
        newPartialState.editingFeature = state.selectedEntity
        return newPartialState
      }),
      actDrawingGeometry: start => set(state => {
        console.log(`actDrawingGeometry start ${start}`)
        const newPartialState: any = {}
        if (start) {
          if (state.isEditingGeometry) {
            console.log(`Cannot do that`)
            return state
          }
          if (state.featureDetailsTab !== 'geometry') {
            newPartialState.isFeatureDetailsDocked = true
            newPartialState.featureDetailsTab = 'geometry'
          }
        }
        newPartialState.drawing_id = state.drawing_id + 1
        newPartialState.isDrawingGeometry = start
        newPartialState.drawingFeature = null
        return newPartialState
      }),
      mainMap: undefined,
      setMainMap: ref => set({ mainMap: ref }),
      flyTo: (coords, zoom, fitBoundsOptions) => {
        const { mainMap } = useUiStore.getState()
        if (mainMap) {
          //return if the coords length is invalid
          if (coords.length < 2 || coords.length > 3) {
            return
          }
          //flyTo() expects the coordinates to be [lat,lng] but in geoJSON they are stored in reverse order
          const latLng = [coords[1], coords[0]]
          mainMap.flyTo(latLng, zoom, fitBoundsOptions)
        }
      },
      panTo: (coords, zoomPanOptions) => {
        const { mainMap } = useUiStore.getState()
        if (mainMap) {
          //return if the coords length is invalid
          if (coords.length < 2 || coords.length > 3) {
            return
          }
          //panTo() expects the coordinates to be [lat,lng] but in geoJSON they are stored in reverse order
          const latLng = [coords[1], coords[0]]
          mainMap.panTo(latLng, zoomPanOptions)
        }
      },
      circleMarkers: [],
      setCircleMarkers: params => {
        const updatedParams = params.map(p => {
          //leaflet expects the coordinates to be [lat,lng] but in geoJSON they are stored in reverse order
          return { ...p, center: [p.center[1], p.center[0]] as CircleMarkerParams['center'] }
        })
        set({ circleMarkers: updatedParams })
      },
      previousScreens: [],
      setPreviousScreens:(screens:string[]) => set((state) => (
          { previousScreens: screens}
      )),
      filterNotificationsBySelectedElement: false,
      setFilterNotificationsBySelectedElement: (val:boolean) => set((state) => (
        { filterNotificationsBySelectedElement: val}
      )),

      mdCompos: {},
      setMdCompoState: (compoState: any) => set(state => {
        const name = compoState.name
        const allStates: any = JSON.parse(JSON.stringify(state.mdCompos))
        let storedCompoState: any = allStates[compoState.name]
        if (!storedCompoState) {
          storedCompoState = { name: name }
          allStates[name] = storedCompoState
        }
        storedCompoState = {...storedCompoState, ...compoState}
        allStates[name] = storedCompoState //we need this line because in the previous line we created a new storedCompoState object by using the spread operator
        return {mdCompos: allStates}
      }),

      composState: {},
      setCompoState: (compoState: any) => set(state => {
        const name = compoState.name
        const allStates: any = JSON.parse(JSON.stringify(state.composState))
        let storedCompoState: any = allStates[compoState.name]
        if (!storedCompoState) {
          storedCompoState = { name: name }
          allStates[name] = storedCompoState
        }
        storedCompoState = {...storedCompoState, ...compoState}
        allStates[name] = storedCompoState //we need this line because in the previous line we created a new storedCompoState object by using the spread operator
        return {composState: allStates}
      }),

    }),
  /**Dev tools  */
  //   {
  //     name: 'uiStore',
  //     enabled: process.env.NODE_ENV === 'development' ? true : false,
  //   }
  // )
)

export { useUiStore }
